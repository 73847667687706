<template>
  <div class="wrap">
    <header-nav></header-nav>
    <div class="steps-wrap">
      <b-container>
        <b-row>
          <b-col>
            <el-steps :active="active" finish-status="success">
              <el-step :title="$t('orderPaymentMethodViewShoppingCart')"></el-step>
              <el-step :title="$t('orderPaymentMethodConfirmOrderInfo')"></el-step>
              <el-step :title="$t('orderPaymentMethodOrderSubmitSuccess')"></el-step>
              <el-step :title="$t('orderPaymentMethodOrderPaymentSuccess')"></el-step>
            </el-steps>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="con-wrap">
      <b-container>
        <b-row v-if="isPay">
          <b-col>
            <div class="complete-payment">
              <img src="@/../public/icon/pay/vipPaymentMethod/d11.jpg" alt="">
              <p>订单已支付成功</p>
              <div class="btn-wrap">
                <div class="btn" @click="toHome">再逛逛</div>
                <div class="btn" @click="toOrderList">查看订单</div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col v-if="paymentMethod == 2">
            <div class="payment-method-title">微信支付</div>
            <div class="main">
              <b-row>
                <b-col sm="12" md="6" lg="6" xl="6" class="code-wrap">
                  <vue-qr :text="qrCode" :size="250"></vue-qr>
                </b-col>
                <b-col sm="12" md="6" lg="6" xl="6" class="example">
                  <img src="@/../public/icon/pay/vipPay/phone.jpg" alt="">
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col v-else-if="paymentMethod == 1">
            <div class="payment-method-title">支付宝支付</div>
            <div class="main">
              <b-row>
                <b-col sm="12" md="6" lg="6" xl="6" class="code-wrap">
                  <vue-qr :text="qrCode" :size="250"></vue-qr>
                </b-col>
                <b-col sm="12" md="6" lg="6" xl="6" class="example">
                  <img src="@/../public/icon/pay/vipPay/alipay-pay-example.jpg" alt="">
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col v-else-if="paymentMethod == 3">
            <div class="payment-method-title">线下结款账户</div>
            <div class="main">
              <img src="@/../public/icon/pay/vipPay/card.jpg" alt="">
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headerNav from "@/components/Navbar";
  import footerNav from "@/components/Footer";
  import dump from "@/util/dump";
  import vueQr from 'vue-qr';
  export default {
    components: {
      headerNav,
      footerNav,
      vueQr
    }, 
    data (){
      return {
        active: 3,
        paymentMethod: 2,
        qrCode: "",
        orderId: "",
        timer: null,
        isPay: false,
        vipId: null
      }
    },
    created() {
      this.init();
    },
    destroyed(){
      clearInterval(this.timer);
    },
    methods: {
      init(){
        this.paymentMethod = this.$route.query.payType;
        this.qrCode = this.$route.query.qrCode;
        this.orderId = this.$route.query.orderId;
        
        // 线下支付不启动
        if(this.paymentMethod == 3) return false;
        this.timer = setInterval(() => {
          this.checkPay();
        },2000)
      },
      checkPay(){
        this.$api.checkOrderPay({
          order_id: this.orderId,
          token: localStorage.getItem("token")
        }).then(res => {
          if(res.status == 100){
            clearInterval(this.timer);
            this.isPay = true;
            this.active = 4;
          }
        }).catch(err => console.error(err));
      },
      toHome(){
        dump.link({
          type: 3,
          link: "/mallIndex"
        })
      },
      toOrderList(){
        dump.link({
          type: 3,
          link: "/usercenter/orderManagement"
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/view/pay/orderPay/orderPay";
</style>
